import { useTrackingInstance } from '@rategravity/1pt-lib';
import { GetStartedLink } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { www } from '../../modules/navigation';
import { HeroicButton } from '../button';
import { Highlight } from '../typography';

const BannerWrapperStyle = createOwnUpStyle({
  maxWidth: '96%',
  marginBottom: 50,
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  variants: {
    mediumAndDown: {
      marginBottom: 0
    }
  }
});

const BannerWrapper = createOwnUpComponent(OwnUpBox, BannerWrapperStyle);

const BannerContentStyle = createOwnUpStyle({
  maxWidth: 400,
  padding: '140px 64px 20px',
  position: 'relative',
  variants: {
    mediumAndDown: {
      paddingTop: 55
    },
    smallAndDown: {
      maxWidth: 250,
      paddingLeft: 32,
      paddingRight: 32
    }
  }
});

const BannerContent = createOwnUpComponent(OwnUpBox, BannerContentStyle);

const BannerTextStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  fontFamily: "'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif'",
  lineHeight: 1.5,
  variants: {
    smallAndDown: {
      fontSize: 30
    },
    medium: {
      fontSize: 35
    },
    large: {
      fontSize: 50
    }
  }
});

const BannerText = createOwnUpComponent(OwnUpHeader, BannerTextStyle);
interface HeroicProps {
  BannerImage: JSX.Element;
}

export const Heroic = ({ BannerImage }: HeroicProps) => {
  const trackingEvent = {
    hook: useTrackingInstance(),
    name: 'clickDiscoverCTA',
    properties: {
      'page-location': '3R-hero'
    }
  };

  return (
    <BannerWrapper>
      {BannerImage}
      <BannerContent>
        <BannerText variant="title">
          <Highlight>3 reasons you&apos;re overpaying for your mortgage</Highlight>
        </BannerText>
        <GetStartedLink www={www} trackingEvent={trackingEvent}>
          <OwnUpBox style={{ marginTop: 30, marginBottom: 30, display: 'inline-block' }}>
            <HeroicButton variant="banner">GET STARTED</HeroicButton>
          </OwnUpBox>
        </GetStartedLink>
      </BannerContent>
    </BannerWrapper>
  );
};
